@import "utils/variables";
@import "vendors/vendors";
@import "fonts/fonts";
@import "components/header";
@import "components/footer";
@import "components/devices-choices";
@import "components/vagon";
@import "components/unity-webgl";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

a {
  color: $violet;
}

ul {
  padding-left: 1rem;
}

li {
  margin-bottom: 1rem;
}

.mockup {
  position: absolute;
  z-index: 9999;
  opacity: 0.3;
  top: 0;
  left: 0;
  // background-image: url(../img/Mockup/mockup.jpg);
  background-repeat: no-repeat;
  width: 100%;
  height: 5000px;
  pointer-events: none;
}

#page-container {
  display: grid;
  grid-template-rows: minmax(146px, auto) 1fr minmax(324px, auto);
  grid-template-areas: "header" "main" "footer";
  .main {
    padding: 20px;
    background-color: $rose-light;
    background-repeat: repeat;
    grid-area: main;
    display: flex;
    justify-content: center;
    color: $violet;
    width: 100%;
    &_content {
      width: 100%;
    }
    &__title {
      font-size: $pt-30;
      margin-bottom: 0;
      font-weight: 600;
      color: $violet;
    }
    &__paragraph {
      margin-top: 0;
      font-size: $pt-20;
      color: $violet;
    }
    .sponsor{
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sponsor__text{
      text-align: right;
    }
    .sponsor__logo{
      background-image: url(../img/boucles-roses.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 260px;
      height: 100px;
      text-indent: -9999px;
      margin: 0;
    }
    @media screen and (min-width:576px) { 
      .sponsor{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
      .sponsor__text{
        margin-right: 10px;
      }
    }
  }
  @media (min-width: 1200px) {
    .main {
      &__content {
        max-width: 1200px;
        width: 100%;
      }
    }
  }
}

#unity-container.unity-mobile {
  width: 100%;
  // height: 100%;

  #unity-canvas {
    width: 100%;
    height: 100%;
  }
}
#unity-canvas {
  background: #000000;
}

#unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
#unity-logo {
  width: 154px;
  height: 130px;
  background: url("../img/unity-logo-dark.png") no-repeat center;
}
#unity-progress-bar-empty {
  width: 141px;
  height: 18px;
  margin-top: 10px;
  background: url("../img/progress-bar-empty-dark.png") no-repeat center;
}
#unity-progress-bar-full {
  width: 0%;
  height: 18px;
  margin-top: 10px;
  background: url("../img/progress-bar-full-dark.png") no-repeat center;
}

#unity-webgl-logo {
  width: 204px;
  height: 38px;
  background: url("../img/webgl-logo.png") no-repeat center;
}

#unity-fullscreen-button {
  width: 38px;
  height: 38px;
  background: url("../img/fullscreen-button.png") no-repeat center;
}

.unity-mobile #unity-footer {
  display: none;
}

#unity-mobile-warning {
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: white;
  padding: 10px;
  display: none;
}

$bg: #02679d;
$bg-header: white;
$bg-footer:#373d49;
$orange:#e77721;
$blue:#0047bb;
$rose-light: #fdcfe9;
$rose-dark:#d7006e;
$violet: #631f79;

$pt-12:0.81rem;
$pt-14:0.95rem;
$pt-20:1.35rem;
$pt-30:2.04rem;
$pt-50:3.4rem;

// transitions
$transition : cubic-bezier(0.42, 0, 0.28, 1.01) 0.3s;



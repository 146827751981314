#page-container.unity .main {
  .unity-header {
    position: relative;
    justify-content: space-between;
    margin-bottom: 10px;

    &__left,
    &__right {
      display: flex;
    }
    &__left {
      flex-direction: column;
      margin-right: 20px;
    }

    &__right {
      display: flex;
      align-items: flex-end;
      padding-bottom: 10px;
      justify-content: flex-end;
      text-align: right;
      p {
        margin: 0;
        margin-right: 10px;
      }
    }
  }

  #unity-build-title {
    display: none;
  }
  #unity-webgl-logo {
    display: none;
  }
  #unity-container {
    position: relative;
    display: flex;
  }

}

.header {
    background-color: $bg-header;
    grid-area: header;
    display: flex;
    justify-content: center;
    padding-right: 20px;
    padding-left: 20px;
    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        display: none;
    }
    &__content-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        @media screen and (min-width: 575px) {
            font-size: 15px;
        }
    }
    &__menu-mobile {
        width: 100%;
    }
    &__menu-mobile-logos{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    &__menu-mobile-list {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style-type: none;
        justify-content: center;
        align-items: stretch;
        height: 90px;
    }
    &__content-mobile-logo {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 20px;
    }
    &__left,
    &__right {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        list-style-type: none;
        padding: 0;
        padding-bottom: 37px;
        margin: 0;
    }
    &__logo {
        background-image: url(../img/svg/logo.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 200px;
        height: 30px;
        text-indent: -9999px;
        margin: 0;
    }
    &__logo-ligue {
        background-image: url(../img/svg/logo-lcc-rose.svg);
        background-repeat: no-repeat;
        width: 90px;
        height: 61px;
        text-indent: -9999px;
        display: block;
        margin: 10px;
    }
    &__nav-item {
        transition: background-color $transition;

        &_up {
            height: 100%;
            background-color: white;
            color: $blue;
            display: flex;
            margin:0;
            height: 100%;
            margin-right: 10px;

            a{
                display: block;
                height: 100%;
        transition: color $transition;

            }
            &:hover:not(.header__nav-item_active){
                background-color: $rose-light;
                a{
                    color: $rose-dark;
                }
            }
        }
        &_active {
            background-color: $rose-dark;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        max-width: 128px;
        color: $rose-dark;
        text-decoration: none;
        display: block;
        font-weight: bolder;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        &_active {
            color: white;
        }
    }
}

@media (min-width: 1200px) {
    .header {
        &__logo {
            width: 391px;
            margin-bottom: 10px;
        }
        &__logo-ligue{
            margin-bottom: 0;
            margin-top: 0;
            width: 118px;
            height: 81px;
        }
        &__content-mobile {
            display: none;
        }
        &__content {
            display: flex;
            max-width: 1200px;
        }
        &__nav-item {
            &_up {
                
                align-items: flex-end;
                margin-bottom: 10px;
                a{
                    height: auto;
                }
            }
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }

        &__link {
            width: 130px;
         
        }
    }
}

.devices-choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../img/backgrounds/fond-niveau0.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    &__button {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: white;
        font-weight: 600;
        text-decoration: none;
        box-sizing: border-box;
        padding: 56px 20px;
        text-shadow: 5px 5px 5px rgb(0 0 0 / 75%);
        border-radius: 25px;
        overflow: hidden;
        position: relative;
        height: 555px;
        &_right {
            margin: 20px 20px 0 20px;
        }
        &_left {
            margin: 20px;
        }
        @media screen and (min-width: 475px) and (max-width: 1200px) {
            max-width: 400px;
            padding: 36px 20px;
        }
    }
    &__first-line {
        font-size: $pt-20;
        margin-top: 1rem;
        margin-bottom: 0;
        z-index: 10;
    }
    &__second-line {
        z-index: 10;
        font-size: $pt-50;
        margin-top: 0;
        margin-bottom: 5rem;
    }
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;

        &_left {
            background: url("../img/svg/button-desktop.svg") no-repeat;
            background-size: 200%;
            background-position-y: bottom;
            background-position-x: center;
        }
        &_right {
            background: url("../img/svg/button-mobile.svg") no-repeat;
            background-size: 250%;
            background-position-y: bottom;
            background-position-x: center;
            @media screen and (min-width: 1200px) {
                background-size: 200%;
            }
        }
    }
}

@media (min-width: 1200px) {
    .devices-choice {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        &__button {
            width: 50%;
            height: 575px;
            margin: 50px;
        }
    }
}

.footer {
    background-color: $bg-footer;
    grid-area: footer;
    display: flex;
    justify-content: center;
    color: white;
    padding-right: 20px;
    padding-left: 20px;
    &__content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__up {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 4px;
        margin-bottom: 60px;
    }
    &__social-title {
        font-size: $pt-20;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__social-list {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        justify-content: space-between;
    }
    &__social-icon {
        display: block;
        width: 40px;
        height: 40px;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: center;
        @media screen and (min-width: 576px) {
            width: 50px;
            height: 50px;
        }
        &_twitter {
            background-image: url(../img/svg/icon-twitter.svg);
        }
        &_youtube {
            background-image: url(../img/svg/icon-youtube.svg);
            width: 60px;
        }
        &_facebook {
            background-image: url(../img/svg/icon-facebook.svg);
        }
        &_instagram {
            background-image: url(../img/svg/icon-instagram.svg);
        }
    }
    &__down {
        align-self: flex-start;
        width: 100%;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__title {
        text-transform: uppercase;
        font-size: $pt-20;
        font-weight: 600;
        margin: 0;
    }
    &__mentions {
        text-transform: uppercase;
        color: white;
        text-decoration: none;
        font-size: $pt-14;
    }
    &__down-hr {
        margin-top: 1px;
    }
    &__address {
        margin-top: 0;
        font-size: $pt-12;
        font-weight: 600;
        line-height: 1.8rem;
    }
    &__copyright {
        font-size: 0.81rem;
    }
   
}


@media (min-width: 1200px) {
 
    .footer {
    
        &__content {
            max-width: 1200px;
        }
    }
}